.bg {
  width: 100%;
  height: 332px;
  padding-bottom: 24px;
  padding-top: 24px;
  background-color: #f5f6fa;
}
.uk-pc-popular-categories {
  width: 1264px;
  height: 284px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;

  .title {
    font-weight: bold;
    height: 28px;
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 28px;
    margin-left: 24px;
    display: flex;
    align-items: center;
  }

  .mySwiper {
    width: calc(100% + 80px);
    margin: 0px -40px 0px -40px;
    padding-left: 20px;
    padding-right: 20px;

    .slideItem {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        border-radius: 50%;
        width: 180px;
        height: 180px;
        object-fit: cover;
      }

      p {
        margin-top: 12px;
        text-align: center;
      }
    }
    .slideItem:hover {
      img {
        filter: brightness(105%);
      }
      p {
        color: #990915;
      }
    }

    .buttonGroup {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateY(-60%);
      transform: translatex(-50%);
      z-index: 999;
      pointer-events: none;

      .prev {
        background-color: #0000001f;
        padding-left: 18px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        pointer-events: auto;
      }
      .next {
        background-color: #0000001f;
        padding-left: 20px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        pointer-events: auto;
      }
      .prev:hover {
        background-color: #0000003d;
      }
      .next:hover {
        background-color: #0000003d;
      }
    }
  }
}
