.scrollContainer {
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
}

.scrollContainerMain {
  overflow: auto;
  width: 100%;
}

/* 隐藏滚动条 */
.scrollContainerMain::-webkit-scrollbar {
  height: 0;
}
