.uk-pc-category-floor {
  height: 328px;
  justify-content: center;
  display: flex;
  margin-left:-24px;
  .img-box-1 {
    width: 288px;
    height: 328px;
    border-radius: 5px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.product-box {
  cursor: pointer;

  .img-box {
    position: relative;
    overflow: hidden;
  }

  .presell {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 24px;
    position: absolute;
    text-align: center;
    color: white;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    background-color: rgba(209, 41, 26, .6);
  }

  .people {
    height: 18px;
    overflow: hidden;
    margin-bottom: 3px;

    span {
      float: left;
      height: 14px;
      line-height: 14px;
      color: #F33727;
      font-size: 12px;
      font-weight: 500;
      padding: 2px 6px;
      border-radius: 4px;
      background: #FFDFDC;
      margin-right: 5px;
    }

    .coupon {
      float: left;
      width: auto;
      height: 18px;
      padding: 0 6px;
      color: #E08E42;
      font-size: 12px;
      line-height: 18px;
      position: relative;
      border-radius: 4px;
      background-color: #FDEBD0;

      ._left,
      ._right {
        top: 7px;
        width: 4px;
        height: 4px;
        position: absolute;
        border-radius: 50%;
        background-color: #FFFFFF;
      }

      ._left {
        left: -2px;
      }

      ._right {
        right: -2px;
      }
    }
  }
}

.uk-pc-category-floor-container6 {
  max-width: 1264px;
  padding-left: 24px;
  padding-right: 24px;
  height: 328px;
  background: rgba(0, 0, 0, 0.02);
  align-items: center;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 24px;
  border-radius:8px;

  .product-box {
    padding: 18px 0;
    border-radius: 10px;
    .img-container {
      width: 200px;
      height: 200px;
      border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .img-box-null {
      width: 220px;
      height: 220px;
      display: grid;
      border-radius: 5px;
      // align-items: center;
      justify-items: center;
      // background-color: #f7f9fe;
      position: relative;

      .img {
        width: 42px;
        height: 26px;
      }
    }
  }
}

.uk-pc-category-floor-container5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;

  .product-box {
    height: 321px;
    padding: 15px;
    border-radius: 10px;
    background-color: #FFFFFF;

    .img-box {
      width: 200px;
      height: 200px;
      border-radius: 5px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .img-box-null {
      width: 200px;
      height: 200px;
      display: grid;
      border-radius: 5px;
      align-items: center;
      justify-items: center;
      background-color: #f7f9fe;
      position: relative;

      .img {
        width: 42px;
        height: 26px;
      }
    }
  }
}

.uk-pc-category-floor-container4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;

  .product-box {
    height: 381px;
    padding: 15px;
    border-radius: 10px;
    background-color: #FFFFFF;

    .img-box {
      width: 260px;
      height: 260px;
      border-radius: 5px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .img-box-null {
      width: 260px;
      height: 260px;
      display: grid;
      border-radius: 5px;
      align-items: center;
      justify-items: center;
      background-color: #f7f9fe;
      position: relative;

      .img {
        width: 42px;
        height: 26px;
      }
    }
  }
}

.uk-pc-category-floor-container3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;

  .product-box {
    height: 381px;
    padding: 15px;
    border-radius: 10px;
    background-color: #FFFFFF;

    .img-box {
      width: 260px;
      height: 260px;
      border-radius: 5px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .img-box-null {
      width: 260px;
      height: 260px;
      display: grid;
      border-radius: 5px;
      align-items: center;
      justify-items: center;
      background-color: #f7f9fe;
      position: relative;

      .img {
        width: 42px;
        height: 26px;
      }
    }
  }
}

.uk-pc-category-floor-container2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;

  .product-box {
    height: 281px;
    padding: 15px;
    border-radius: 10px;
    background-color: #FFFFFF;

    .img-box {
      width: 220px;
      height: 220px;
      border-radius: 5px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .img-box-null {
      width: 220px;
      height: 220px;
      display: grid;
      border-radius: 5px;
      align-items: center;
      justify-items: center;
      background-color: #f7f9fe;
      position: relative;

      .img {
        width: 42px;
        height: 26px;
      }
    }
  }
}

.uk-pc-category-floor-container1 {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 10px;

  .product-box {
    height: 381px;
    padding: 15px;
    border-radius: 10px;
    background-color: #FFFFFF;

    .img-box {
      width: 260px;
      height: 260px;
      border-radius: 5px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .img-box-null {
      width: 260px;
      height: 260px;
      display: grid;
      border-radius: 5px;
      align-items: center;
      justify-items: center;
      background-color: #f7f9fe;
      position: relative;

      .img {
        width: 42px;
        height: 26px;
      }
    }
  }
}

.uk-pc-category-floor-container0 {
  display: none;
}

.commodity-title{
  width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  margin: 12px 0;
}

.commodity-text {
  margin: 10px 0;
  font-size: 14px;
  height: 40px;
  line-height: 20px;
  color: var(--c7);
  font-weight: normal;
  //text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.commodity-price {
  color: #1A1A1A;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
  font-family: JDZhengHT-EN;
  font-weight: 400;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .price {
    font-size: 28px;
  }
}

.original-price {
  margin-top: 5px;
  font-size: 14px;
  color: var(--c5);
  text-decoration: line-through;
}

.add-cart {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #F7F8FC;
  z-index: 2;
  // background-image: url("./img/cart.svg");
  // // background-size: 53%;
  background-position: center;
  background-repeat: no-repeat;
}
