.best-seller-wrapper {
  .flag {
    position: absolute;
    transform: scale(0.5);
    transform-origin: left top;
    top: 0;
    left: 0;
    z-index: 55;
  }
  .product {
    margin-bottom: 16px;
    height: 296px;
    display: flex;
    gap: 16px;
    align-items: center;     
  }
  .title {
    width: 180px;
    height: 32px;
    background: url("../img/seller-title.png") no-repeat;
    background-size: 100%;
  }
  .right-zone {
    width:496px;
    height:296px;
    padding: 20px 16px;
    background: rgba(0, 0, 0, 0.02);
    display: flex;
    // gap: 16px;
    flex-wrap: wrap;
  }
  .main-img{
    max-width: 100%;
    max-height:100%;
    margin:0  auto;
  }
  .image-wrapper{
    position: relative;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .product-item-left{
      border-radius:4px;
      width: 240px;
      height:100%;
      &:after{
        border-radius:4px;
      }
      .price-wrapper {
        padding-left:12px;
        margin: 12px 0;
        width:100%;
        height: 32px;
        line-height: 32px;
        display: flex;
        align-items: baseline;
        font-family: JDZhengHT-EN;
        font-size: 16px;  
        .real-price {
          color: #1a1a1a;
          .price-1 {
            font-size: 28px;
          }
        }
        .origin-price {
          margin-left: 8px;
          height: 28px;
          line-height: 28px;
          text-decoration: line-through;
          color: #888b94;
        }
      }
      .image-wrapper{
        width: 240px;
        height: 240px;
      }
  }
  .product-item-right {
    width: 224px;
    height: 120px;
    display: flex;
    align-items: center;
    &:hover{
      background: rgba(0, 0, 0, 0.02);
    }
    .price-wrapper {
      height: auto;
      width: 100%;
      flex: 1;
      display:flex;
      align-items: center;
      justify-content: flex-start;
      .real-price {
        .price-1 {
          font-size: 24px;
        }
      }
      .origin-price {
        margin-left: 0;
        font-size: 14px;
      }
    }
    .image-wrapper {     
      width: 120px;
      height: 120px;      
    }
  }
  
}

