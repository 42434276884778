.uk-pc-newcomer {
  width: 1264px;
  margin-left: auto;
  margin-right: auto;

  .bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .title {
      height: 32px;
      width: 300px;
      font-weight: 400;
      // background: url("./img/newcomer-title.png") no-repeat left;
      background-size: contain;
    }

    .more {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      height: 24px;
    }
  }

  .products {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .product {
      position: relative;

      label {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        background-color: #fff3e7;
        padding: 1px 4px;
        border-radius: 2px;
        color: #fe800c;
        z-index: 100;
      }

      .price {
        .v1,
        .v2,
        .v3 {
          font-family: JDZhengHT-EN;
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
          color: #1a1a1a;
        }

        .v2 {
          font-size: 28px;
        }

        .v4 {
          font-family: JDZhengHT-EN;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          text-align: left;
          color: #888b94;
          margin-left: 8px;
          text-decoration: line-through;
        }
      }

      .img-box {
        width: 240px;
        height: 240px;
        display: flex;
        position: relative;
        margin-bottom: 12px;
        background-color: #ffffff;

        .mask {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02));
          border-radius: 4px;
        }

        &:hover {
          .mask {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)),
              linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
          margin: auto;
        }
      }
    }
  }
}
