.img-container {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: relative; /* 使伪元素相对于容器定位 */
}

.successImg {
  cursor: pointer;
  position: relative; /* 使子元素相对于此元素定位 */
}

.successImg img {
  width: 100%;
  height: 100%;
}

.successImg:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.02); /* 2% 黑色覆盖 */
  border-radius: 5px; /* 确保覆盖层与容器边缘一致 */
}

.errorImg {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorImg img {
  width: 100%;
  height: 100%;
}