.container {

  height: 352px;
  margin-bottom: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  background: rgb(0, 0, 0, 0.02);
  border-radius: 8px;


  .buttonContainer {
    margin-left: auto;
  }

  // Header
  .header {
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    max-height: 72px;
    height: 100%;

    .title {
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
    }

    // Button
    .button {
      height: 24px;
      margin-left: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      :hover {
        color: #990915;
      }

      .land_text {
        font-size: 18px;
        line-height: 24px;
        margin-right: 8px;
      }

      .arrow_right {
        width: 16px;
        height: 16px;
      }
    }
  }

  // Card Container
  .cardContainer {
    display: flex;
    height: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;

    .contentContainer {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 28px;
      align-items: center;
    }
  }

}