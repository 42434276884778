.tagGroup {
  display: flex;
  gap: 2px;

  .tips1 {
    color: white;
    background-color: #cc0c1c;
    border-radius: 2px;
    text-align: center;
    // width: 58px;
    padding: 2px 6px;
    height: 22px;
    font-size: 14px;
  }

  .tips2 {
    color: #fa2c19;
    font-size: 16px;
  }

  .placeHolder {
    height: 22px;
    width: 58px;
    color: white;
  }
}
