.uk-pc-image-ads {
  width: 1264px;
  height: 205px;
  margin-left: auto;
  margin-right: auto;

  .adsModule {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 17px;

    img:hover {
      filter: brightness(102%);
    }

    Image {
      border-radius: 8px;
    }

    .mySwiper {
      .slideItem {
        display: flex;
        flex-direction: column;
        align-items: center;

        Image {
          border-radius: 8px;
        }
      }

      .buttonGroup {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
        pointer-events: none;

        .prev {
          background-color: #0000001f;
          padding-left: 16px;
          width: 40px;
          height: 48px;
          border-radius: 0 24px 24px 0;
          pointer-events: auto;
        }
        .next {
          background-color: #0000001f;
          padding-left: 16px;
          width: 40px;
          height: 48px;
          border-radius: 24px 0 0 24px;
          pointer-events: auto;
        }
        .prev:hover {
          background-color: #0000003d;
        }
        .next:hover {
          background-color: #0000003d;
        }
      }
    }
  }
}
