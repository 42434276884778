
.uk-pc-new-arrival {
  width: 1264px;
  margin: 0 auto;
  & ul::after {
    display: block;
    content: '';
    clear: both;
  }
  .editor-tips {
    font-size: 14px;
    // color: #77798e;
    color: var(--rcd-color-text-200, #77798E);
    height: 72px;
    line-height: 72px;
    text-align: center;
  }

  .module-title {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    margin-bottom: 16px;

    .module-title-text {
      width:191px;
      height:32px;
      background:url('./img//new-arrival.png') no-repeat;
      background-size:100%;
    }
    

    .module-title-icon {
      width: 32px;
      height: 32px;
      margin-right: 12px;
      display: inline-block;
      vertical-align: top;
    }

    .module-titl-all {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      cursor: pointer;
      display:flex;
      align-items: center;
    }

    .module-title-right {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      background:url('./img/chevron-Right.png') no-repeat;
      background-size:100%;
    }
  }
  .imgBox{
    width: 100%;
    height: 100%;
  }
  .successImg {
    width: 100%;
    height: 100%;
    font-size: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
  .errorImg {
    width: 100%;
    text-align: center;
    font-size: 0;
    img {
      width: 100%;
    }
  }
  .productList-box{
    display:flex;
    justify-content: space-between; 
  }
  .product {
    padding:16px 32px;
    width: 304px;
    height: 304px;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    &:after{
      border-radius:4px;
    }
    ._content {
      margin:0 auto;
      ._img_box {
        margin:0 auto;
        display:flex;
        align-items: center;
        flex:1;
        width: 190px;
        height: 190px;
        cursor:pointer
      }
      ._img{
        margin:0 auto;
        max-height:100%;
        max-width:100%;
      }
  
      .title {
        margin-top:12px;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        width: 100%;
        margin-bottom: 12px;
      }
  
      .price-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
  
        .add-cart {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #F7F8FC;
          // background-image: url(./img/addCart.png);
          // background-size: 60% 50%;
          // background-position: 45% 50%;
          // background-repeat: no-repeat;
          // transform: rotateY(0deg);
          cursor: pointer;
          // &:hover {
          //   background-image: url(./img/addCartHover.png);
          // }
        }
      }
    }

   
  }
  .price-floor {
    height: 32px;
    display: flex;
    align-items: baseline;

    .price {
      margin-right: 8px;
      font-family: JDZhengHT-EN;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;
      color: #1a1a1a;

      .v1,
      .v2,
      .v3 {
        font-family: JDZhengHT-EN;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #1a1a1a;
      }

      .v2 {
        font-size: 28px;
        font-weight: bold;
        margin-top: 16px;
      }

      .v4 {
        font-family: JDZhengHT-EN;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        color: #888b94;
        margin-left: 8px;
        text-decoration: line-through;
      }
    }
  }

  .score {
    display: flex;
    align-items: center;
    text-align: left;
    margin-top: 4px;

    .star {
      display: flex;
      align-items: center;

      svg {
        margin-right: 2px;
      }
    }

    .tips {
      margin-left: 6px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #CC6B0A;
    }
  }


}

