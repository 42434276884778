.discount_list_wrap {
  width: 1264px;
  height: 368px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background: #00000005;
  border-radius: 8px;

  .title {
    font-weight: bold;
    height: 72px;
    font-size: 24px;
    line-height: 28px;
    margin-left: 24px;
    display: flex;
    align-items: center;
  }

  .land_btn {
    top: 24px;
    right: 24px;
    gap: 8px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .land_text {
      font-size: 18px;
      line-height: 24px;
    }

    .arrow_right {
      width: 16px;
      height: 16px;
    }
  }

  .land_btn:hover {
    .land_text {
      color: #990915;
    }

    .arrow_right {
      svg,
      path {
        stroke: #990915;
      }
    }
  }

  .card_wrap {
    padding: 0px 26px 24px 26px;

    .card_slider {
      gap: 28px;
      width: 1212px;
      height: 272px;
      display: flex;
      align-items: center;
    }
  }
}
