.uk-pc-carousel {
  min-width: 1264px;
  width: 100%;
  overflow: hidden;
  background-color: transparent;

  .carousel {
    overflow: hidden;
    position: relative;
    border-radius: 0;
    background-origin: border-box; // 从border开始填充
    background-clip: border-box; // border外的多余背景图片不展示，截取掉
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background: rgba(0, 0, 0, 0.02);

    &:hover,
    &:focus {
      opacity: 0.9;
    }
    .swiper-wrapper {
      width: 100%;     
      .img-box{
        display:flex;
        width:100%;
        height:100%;
        // &:hover,
        // &:focus {
        //   opacity: 0.9;
        // }
      }
      ._img{
        min-width: 1264px;
        margin:0 auto;
      }
      .swiper-item {
        display:flex;
        justify-content: center;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        text-align: center;
        -moz-background-size: contain;
        -webkit-background-size: contain;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-origin: border-box;
        background-clip: border-box;
        opacity: 0; /*  初始不透明度为0，图片都看不见 */
        pointer-events: none;
        transition: opacity 1s linear; /* --重点--定义一个关于透明度的 */

        &.active {
          pointer-events: auto;
          opacity: 1; /* 有active类的图片不透明度为1，即显示图片 */
          cursor: pointer;
        }
      }
    }

    .paginations {
      position: absolute;
      left: 0;
      bottom: 16px;
      width: 100%;
      text-align: center;
      font-size: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        width: 6px;
        height: 6px;
        margin: 0 3px;
        border-radius: 50%;
        display: inline-block;
        background: rgba(255, 255, 255, 0.4);
        border: 0.67px solid rgba(0, 0, 0, 0.05);

        &.active {
          background: #ffffff;
          border: 2px solid rgba(0, 0, 0, 0.1);
        }
      }
    }

    .paginations-square {
      span {
        width: 30px;
        height: 6px;
        border-radius: 0;

        &.active {
          border-radius: 0;
          background-color: #ffffff;
        }
      }
    }

    .left-button {
      width: 20px;
      height: 24px;
      // left: 0;
      //left:232px;
      //left:calc(50% - 1264px) / 2;
      top: calc(50% - 12px);
      position: absolute;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      //background-color: rgba(0, 0, 0, 0.2);
      &:hover {
        background-color: rgba(0, 0, 0, 0.4);
      }

      svg {
        // margin-left: 5px;
        transform: scaleX(1) /* rtl:scaleX(-1) */;
      }
    }

    .right-button {
      width: 20px;
      height: 24px;
      //right: 0;
      //right:232px;
      //right:calc(100% - 1264px) / 2;
      display: flex;
      align-items: center;
      top: calc(50% - 12px);
      position: absolute;
      text-align: right;
      cursor: pointer;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      //background-color: #000000;
      //opacity: 0.12;
      &:hover {
        //opacity: 0.24;
        background-color: rgba(0, 0, 0, 0.4);
      }

      svg {
        transform: scaleX(1) /* rtl:scaleX(-1) */;
      }
    }
  }

  // 示例图
  .example {
    height: 200px;
    display: flex;
    justify-items: center;
    align-items: center;
    background-color: #f7f9fe;

    img {
      width: 80px;
      height: 50px;
      margin: 0 auto;
    }
  }

  // 骨架图
  .skeleton {
    height: 140px;
    display: flex;
    justify-items: center;
    align-items: center;
    background-color: #f4f4f4ff;

    img {
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }
  }
}
