.best-seller-header {
  .header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  .title {
    width: 180px;
    height: 32px;
    background: url("../img/seller-title.png") no-repeat;
    background-size: 100%;
  }
  .arrow {
    display:flex;
    align-items: center;
    justify-content: end;
    width: 43px;
    height: 32px;
    line-height: 32px;
    font-size: 18px;
    box-sizing: border-box;
  }
}
