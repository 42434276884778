// 统一的卡片浮层样式
.FloatingLayer{
    position: relative;
    cursor: pointer;
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background: rgba(225, 225, 225, 0.02);
      }
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background:  rgba(225, 225, 225, 0.02);
      }
      &:hover {
        &::after {
          z-index: 1;
          background: rgba(225, 225, 225, 0.1);
        }
      }
}

// 统一的hover字体变红样式
.HOVERRED{
    &:hover{
        color:rgba(255, 63, 79, 1);
        svg path{
          stroke:rgba(255, 63, 79, 1);
        }
    }
}

//统一的购物车样式
.HOVERCAR{
  &:hover{
      color:rgba(255, 63, 79, 1);
      svg path{
        fill:rgba(255, 63, 79, 1);
      }
  }
}

