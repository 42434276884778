$CouponItemHorizontalGap: 16px;

.Wrapper {
  position: relative;
  $ControllerWidth: 48px;
  $ControllerGap: 20px;
  .ScrollContainerControllerLeft,
  .ScrollContainerControllerRight {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: $ControllerWidth;
  }
  .ScrollContainerControllerLeft {
    left: calc(-#{$ControllerWidth} - #{$CouponItemHorizontalGap});
    transform: translateY(-50%);
  }
  .ScrollContainerControllerRight {
    right: calc(-#{$ControllerWidth} - #{$CouponItemHorizontalGap});
    transform: rotate(180deg) translateY(50%);
  }
}

.CouponList {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  align-items: stretch;
}
.CouponItem {
  background-color: #fff;
  padding: 15px 16px;
  box-sizing: border-box;
  min-height: 88px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  &:not(:first-child) {
    margin-left: #{$CouponItemHorizontalGap / 2};
  }
  &:not(:last-child) {
    margin-right: #{$CouponItemHorizontalGap / 2};
  }
  &.OnePerRow {
    width: 100%;
    .left {
      font-size: 18px;
      line-height: 34px;
      justify-content: flex-start;
      align-items: flex-end;
      display: flex;
      flex: 1;
      overflow: hidden;
      .off {
        display: flex;
        align-items: flex-end;
        .offDiscount {
          .discount {
            font-size: 48px;
            line-height: 48px;
          }
          .subscript {
            font-size: 24px;
            margin-left: 8px;
            line-height: 40px;
          }
          .quotaLimit {
            font-size: 24px;
            line-height: 40px;
            margin-left: 12px;
          }
        }
      }
      .couponUseLimit {
        line-height: 34px;
        margin-left: 16px;
        font-size: 18px;
      }
    }
    .right {
      button {
        height: 40px;
        padding: 8px 24px;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .CountdownBar {
      height: 48px;
      padding: 12px 16px;
      border-radius: 6px;
      .timeDescText {
        font-size: 18px;
        line-height: 24px;
        margin-right: 8px;
      }
      .box {
        font-weight: 500;
        line-height: 14px;
        font-size: 18px;
        border-radius: 4px;
        color: #fff;
        width: 32px;
        height: 24px;
      }
      .colon {
        margin: 4px;
        width: 4px;
        size: 14px;
        line-height: 13px;
        transform: translateY(-1px);
      }
    }
  }
  &.TwoPerRow {
    width: calc(50% - #{$CouponItemHorizontalGap * 1 / 2});
    .left {
      font-size: 18px;
      line-height: 34px;
      flex-direction: column;
      display: flex;
      flex: 1;
      overflow: hidden;
      .off {
        display: flex;
        align-items: flex-end;
        .offDiscount {
          .discount {
            font-size: 32px;
            line-height: 32px;
          }
          .subscript {
            font-size: 16px;
            margin-left: 4px;
            line-height: 20px;
          }
          .quotaLimit {
            font-size: 16px;
            line-height: 20px;
            margin-left: 12px;
          }
        }
      }
      .couponUseLimit {
        line-height: 18px;
        margin-top: 8px;
        font-size: 14px;
      }
    }
    .right {
      button {
        height: 32px;
        padding: 7px 16px;
        font-size: 14px;
        line-height: 18px;
      }
    }
    .CountdownBar {
      height: 48px;
      padding: 8px;
      border-radius: 6px;
      .timeDescText {
        font-size: 14px;
        line-height: 18px;
        margin-right: 8px;
      }
      .box {
        font-weight: 500;
        line-height: 10px;
        font-size: 14px;
        border-radius: 4px;
        color: #fff;
        width: 24px;
        height: 24px;
      }
      .colon {
        margin: 2px;
        width: 4px;
        size: 14px;
        line-height: 13px;
        transform: translateY(-1px);
      }
    }
  }
  &.MultiplePerRow {
    width: calc(33.33% - #{$CouponItemHorizontalGap * 2 / 3});
    .left {
      font-size: 18px;
      line-height: 34px;
      flex-direction: column;
      display: flex;
      flex: 1;
      overflow: hidden;
      .off {
        display: flex;
        align-items: flex-end;
        .offDiscount {
          .discount {
            font-size: 32px;
            line-height: 32px;
          }
          .subscript {
            font-size: 16px;
            margin-left: 4px;
            line-height: 20px;
          }
          .quotaLimit {
            font-size: 16px;
            line-height: 20px;
            margin-left: 12px;
          }
        }
      }
      .couponUseLimit {
        line-height: 18px;
        margin-top: 8px;
        font-size: 14px;
      }
    }
    .right {
      button {
        height: 32px;
        padding: 7px 16px;
        font-size: 14px;
        line-height: 18px;
      }
    }
    .CountdownBar {
      height: 48px;
      padding: 8px;
      border-radius: 6px;
      .timeDescText {
        font-size: 14px;
        line-height: 18px;
        margin-right: 8px;
      }
      .box {
        font-weight: 500;
        line-height: 10px;
        font-size: 14px;
        border-radius: 4px;
        color: #fff;
        width: 24px;
        height: 24px;
      }
      .colon {
        margin: 2px;
        width: 4px;
        size: 14px;
        line-height: 13px;
        transform: translateY(-1px);
      }
    }
  }

  &.Disabled {
    $BorderColor: #c2c4cc;
    $FontColor: #c2c4cc;

    border: 1px $BorderColor solid;
    .CouponContent {
      .left {
        color: $FontColor;
        .offDiscount {
          .subscript {
            color: $FontColor;
          }
        }
        .couponUseLimit {
          color: $FontColor;
        }
      }
    }
    .LeftPrefix {
      background: $BorderColor;
    }
  }
  &.Off {
    $BorderColor: #ff9fa7;
    $FontColor: #ff0f23;

    border: 1px $BorderColor solid;
    .CouponContent {
      .left {
        color: $FontColor;
        .offDiscount {
          .subscript {
            color: $FontColor;
          }
        }
      }
    }
    .LeftPrefix {
      background: $BorderColor;
    }
  }
  &.ShippingOff {
    $BorderColor: #ace4b4;
    $FontColor: #269636;

    border: 1px $BorderColor solid;
    .CouponContent {
      .left {
        color: $FontColor;
        .offDiscount {
          .subscript {
            color: $FontColor;
          }
        }
      }
    }
    .LeftPrefix {
      background: $BorderColor;
    }
  }

  .CouponContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    height: fit-content;
    position: relative;
    .left {
      display: flex;
      flex: 1;
      overflow: hidden;
      .off {
        display: flex;
        align-items: flex-end;
        white-space: nowrap;
        .offDiscount {
          .discount {
            font-family: JDZhengHT-EN;
            white-space: nowrap;
          }
          .subscript {
            white-space: nowrap;
          }
        }
      }
      .couponUseLimit {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #888b94;
        font-size: 14px;
        width: 100%;
      }
    }
    .right {
      width: fit-content;
      margin-left: 8px;
      z-index: 2;
      display: flex;
      align-items: center;
      height: 100%;
      button {
        box-shadow: none;
        box-sizing: border-box;
      }
    }
  }
  .CountdownBar {
    margin-top: 12px;
    display: flex;
    align-items: center;
    .timeDescText {
      color: #1a1a1a;
    }
    .number {
      color: #fff;
    }
    .box {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.InProgress {
      background: #fff3f4;
      .box {
        background: #ff0f23;
      }
      .colon {
        color: #ff0f23;
      }
    }
    &.NotStarted {
      background: #fff8ea;
      .box {
        background: #fbb731;
      }
      .colon {
        color: #fbb731;
      }
    }
  }
  .Watermark {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    z-index: 1;
  }
  .LeftPrefix {
    width: 4px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
