.uk-pc-newcomer-land {
  width: 100%;

  .header_background {
    width: 100%;
    height: 120px;
  }

  .content {
    display: grid;
    place-items: center;

    .content_wrap {
      width: 1264px;
      margin: 20px auto;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 15px;


      .content_item {
        width: 19%;
        height: 354px;
        margin: 10px 0;

        .image_box {
          border-radius:4px;
          width: 100%;
          height: 240px;
          position: relative;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain
          }
        }

        .image_box::before,
        ::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 240px;
          pointer-events: none;
        }

        .image_box::before {
          background-color: rgba(0, 0, 0, 0.02);
          z-index: 1;
        }

        .image_box::after {
          background-color: rgba(255, 255, 255, 0.1);
          z-index: 2;
          display: none;
          cursor: pointer;
        }

        .image_box:hover:after {
          display: block;
        }

        .content_item_main { 
          padding:0 12px;        
          width: 100%;
          .item_main_title {           
            // width: 100%;
            color: #19191b;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
            margin-top: 6px;
          }

          .item_main_title:hover {
            color: #FF0F23;
          }

          .item_main_amount_money_box {
            width: 100%;
            margin: 10px 0;
            height: 30px;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            line-height: 30px;
            align-items:  flex-end;

            .amount_money_seckill {
              color: #FF0F23;
              font-size: 24px;
              font-weight: 500;
              white-space: nowrap;

              .amount_money_euro {
                font-size: 15px;
              }
            }

            .amount_money_original_price {
              margin: 0 10px;
              font-size: 15px;
              text-decoration: line-through;
              color: #888b94;
              white-space: nowrap;
            }
          }

          .item_buy_now_btn {
            width: 100%;
            border: 1px solid #FF0F23;
            border-radius: 4px;
            color: #FF0F23;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 0;
            background-color: #fff;
            cursor: pointer;
            user-select: none;

          }

          .item_buy_now_btn:active {
            background-color: #eee;
          }

          .item_out_stock_btn {
            width: 100%;
            border: 1px solid #C2C4CC;
            border-radius: 4px;
            color: #C2C4CC;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 0;
            background-color: #fff;
            user-select: none;

          }

        }
      }


    }

    .view_more {
      display: flex;
      align-items: center;
      padding: 10px 16px 10px 16px;
      gap: 4px;
      border-radius: 4px;
      border: 1px solid #E0E1E5;
      cursor: pointer;
      user-select: none;
    }

    .view_more:active {
      background-color: #eee;
    }



  }
  .loading_wrap{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 50px;
  }
  .loading {
    display: inline-block;
    padding: 10px;
    margin: 24px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #C2C4CC;

    .loadingIcon {
      display: inline-block;
      width: 24px;
      height: 24px;
      animation: spin 1s linear infinite;
      margin-right: 10px;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(-360deg);
      }
    }
  }

  .noMore {
    color: #C2C4CC;
    font-size: 14px;
    height: 18px;
    line-height: 18px;
    margin: 12px auto;
    text-align: center;
  }

  .errorBox {
    width: 100%;
    min-height: 150px;
    display: flex;
    justify-content: center;

    .emptyImg {
      width: 130px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 80px;
    }

    .emptyTxt {
      color: #666666;
      font-size: 14px;
      text-align: center;
    }
  }



}