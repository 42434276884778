.uk-pc-hotspot {
  &:hover {
    opacity: 0.9;
  }
  .content {
    position: relative;
    height: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    overflow: hidden;
    div[action="link"] {
      position: absolute;
      cursor: pointer;
    }
  }

  margin-bottom: var(--WS4, 12px);
  margin-left: var(--WS3, 0);
  margin-right: var(--WS3, 0);
}
